import {callUrl} from "./api";

export default {
    async getTableauxBord(data = {}) {
        try {
            let res = await callUrl("GET", 'api/tableau_bords', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getTableauBord(tableauBordId) {
        try {
            let res = await callUrl("GET", `api/tableau_bords/${tableauBordId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchTableauBord(tableauBordId, data) {
        return await callUrl("PATCH", `api/tableau_bords/${tableauBordId}`, data);
    },

    async deleteTableauBord(tableauBordId) {
        return await callUrl("DELETE", `api/tableau_bords/${tableauBordId}`);
    },

    async addTableauBord(data) {
        return await callUrl("POST", `api/tableau_bords`, data);
    },


    async getTableauBordLignes(tableauBordId) {
        return await callUrl("GET", `api/tableau_bord_lignes/${tableauBordId}`)
    },

    async saveLines(tableauBordId, lignes, validate) {
        return await callUrl("POST", `api/tableau_bord_lignes/${tableauBordId}/save/${validate}`, lignes)
    },

    async updateCoef(coef) {
        return await callUrl("POST", `api/tableau/bord/update/coef`, {coef});
    }
}