<template>
    <div>
        <loading-card v-show="fetchingTableau || saving">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else>Chargement du tableau de bord ...</template>
        </loading-card>

        <div v-show="!(fetchingTableau || saving)" class="tableau_bord">
            <b-form @keydown.enter.prevent>
                <validation-observer ref="simpleRules">
                    <b-row>
                        <b-col lg="12">
                            <b-row>
                                <h4 class="card-title float-left ml-2 mt-1">Tableau de Bord du :</h4>
                                <b-col lg="4" sm="6">
                                    <b-form-group label-for="dateTableauBord">
                                        <validation-provider #default="{ errors }" name="Date" rules="required">
                                            <b-form-datepicker
                                                locale="fr" 
                                                :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                                v-model="tableauBord.date"
                                                id="dateTableauBord"
                                                placeholder="Date"
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="!creating"
                                            />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <span lg="2" class="ml-2">Coefficient reliquat</span>
                                <b-col lg="4" sm="6">
                                    <b-form-group label-for="dateTableauBord">
                                        <validation-provider #default="{ errors }" name="coefReliquat" rules="required">
                                            <b-form-input
                                                type="number"
                                                id="coefReliquat"
                                                v-model.lazy="coefReliquat"
                                                @change="onCoefChanged($event)"
                                                placeholder="0.16"
                                                :style="{maxWidth: '200px', fontSize: '1rem', height: '30px'}"
                                                number
                                                :disabled="tableauBord.valid"
                                                :class="errors.length > 0 ? 'is-invalid' : ''"
                                            />
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                <b-row v-if="!creating">
                    <b-col lg="12">
                        <b-card class="decmontant" title="Gestion Frais Généraux et Tableau de Bord">
                            <table class="w-100 tableau-bord">
                                <template v-for="({title, rows}, blocCode, index) in blocs">
                                    <tr :key="`bloc-header-row-${blocCode}`" v-if="index == 0">
                                        <th class="border-right-dark" :style="{width: '33%'}">Libellé</th>
                                        <th :style="{width: '10%'}">Prévu année</th>
                                        <th :style="{width: '10%'}">% Prévu année</th>
                                        <th :style="{width: '10%'}">Prévu à date (<b>{{formatNumber(percentagePrevu, "%")}}</b>)</th>
                                        <th :style="{width: '10%'}">Réalisé à date</th>
                                        <th :style="{width: '10%'}">% Réalisé à date</th>
                                    </tr>

                                    <tr :key="`bloc-title-row-${blocCode}`" class="text-primary title-row">
                                        <th colspan="7777">{{title}}</th>
                                    </tr>

                                    <template v-if="!fetchingRows">
                                        <tr v-for="ligne in getLignesForBloc(blocCode)" :key="`row-${ligne.type.code}`">
                                            <td class="border-right-dark">{{ligne.type.libelle}}</td>
                                            <td>
                                                <b-form-group>
                                                    <validation-provider #default="{ errors }" :name="`${ligne.type.code}-prevuAnnee`" rules="required">
                                                        <b-form-input
                                                            :type="tableauBord.valid ? 'text' : 'number'"
                                                            :id="`${ligne.type.code}-prevuAnnee`"
                                                            :value="tableauBord.valid ? formatNumber(ligne.prevuAnnee) : ligne.prevuAnnee"
                                                            @input="onPrevuAnneeChangedDebounced($event, ligne)"
                                                            placeholder="0"
                                                            :style="{maxWidth: '200px', fontSize: '1rem', height: '30px'}"
                                                            number
                                                            :disabled="tableauBord.valid || !ligne.type.allowManualEdit"
                                                            :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        />
                                                    </validation-provider>
                                                </b-form-group>
                                            </td>
                                            <td class="border-right-dark" :style="{width: '220px !important'}">
                                                <validation-provider #default="{ errors }" :name="`prevuAnneePercent-${ligne.type.code}`" :rules="ligne.type.hasPercentages ? 'required' : ''">
                                                    <b-form-group>
                                                        <b-form-input
                                                            :id="`${ligne.type.code}-prevuAnneePercent`"
                                                            v-if="ligne.type.hasPercentages"
                                                            :value="formatNumber(ligne.prevuAnneePercent, '%')"
                                                            placeholder="0"
                                                            :style="{maxWidth: '100px', fontSize: '1rem', height: '30px'}"
                                                            number
                                                            :disabled="true"
                                                            :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        />
                                                    </b-form-group>
                                                </validation-provider>
                                            </td>
                                            <td class="border-right-dark" :style="{paddingRight: '10px !important'}">
                                                <b-form-group>
                                                    <validation-provider #default="{ errors }" :name="`prevuDate-${ligne.type.code}`">
                                                    <b-form-input
                                                        :id="`${ligne.type.code}-prevuDate`"
                                                        :value="formatNumber(ligne.prevuDate)"
                                                        placeholder="0"
                                                        :style="{maxWidth: '200px', fontSize: '1rem', height: '30px'}"
                                                        :disabled="true"
                                                        number
                                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                                    />
                                                    </validation-provider>
                                                </b-form-group>
                                            </td>
                                            <td>
                                                <b-form-group>
                                                    <validation-provider #default="{ errors }" :name="`realiseDate-${ligne.type.code}`" :rules="ligne.type.allowManualEditRealiseDate ? 'required' : ''">
                                                        <b-form-input
                                                            :type="!ligne.type.allowManualEditRealiseDate || tableauBord.valid ? 'text' : 'number'"
                                                            :id="`${ligne.type.code}-realiseDate`"
                                                            @input="onRealiseDateChanged($event, ligne)"
                                                            :value="(!ligne.type.allowManualEditRealiseDate || tableauBord.valid) ? formatNumber(ligne.realiseDate) : ligne.realiseDate"
                                                            placeholder="0"
                                                            :style="{maxWidth: '200px', fontSize: '1rem', height: '30px'}"
                                                            number
                                                            :disabled="!ligne.type.allowManualEditRealiseDate || tableauBord.valid"
                                                            :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        />
                                                    </validation-provider>
                                                </b-form-group>
                                            </td>
                                            <td>
                                                <b-form-group>
                                                    <validation-provider #default="{ errors }" :name="`realiseDatePercent-${ligne.type.code}`">
                                                        <b-form-input
                                                            :id="`${ligne.type.code}-realiseDatePercent`"
                                                            v-if="ligne.type.hasPercentages"
                                                            :value="formatNumber(ligne.realiseDatePercent, '%')"
                                                            placeholder="0"
                                                            :style="{maxWidth: '100px', fontSize: '1rem', height: '30px'}"
                                                            number
                                                            :disabled="true"
                                                            :class="errors.length > 0 ? 'is-invalid' : ''"
                                                        />
                                                    </validation-provider>
                                                </b-form-group>
                                            </td>
                                        </tr>

                                        <tr v-if="index ==0" :key="`bloc-total-row-${blocCode}`">
                                            <th>Total</th>
                                            <th class="text-left">{{formatNumber(fraisGenerauxTotal)}}</th>
                                            <th></th>
                                            <th></th>
                                            <th class="text-left">{{formatNumber(fraisGenerauxTotalReal)}}</th>
                                            <th></th>
                                        </tr>
                                        <tr v-if="index ==1" :key="`bloc-total-row-${blocCode}`">
                                            <th>Total</th>
                                            <th class="text-left">{{formatNumber(resteAFaireTotal)}}</th>
                                            <th></th>
                                            <th></th>
                                            <th class="text-left">{{formatNumber(resteAFaireTotalReal)}}</th>
                                            <th></th>
                                        </tr>
                                    </template>

                                    <tr v-else :key="`bloc-skeleton-${blocCode}`">
                                        <td colspan="7777" class="skeleton-table">
                                            <b-skeleton-table :rows="rows" :columns="6"/>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </b-card>
                    </b-col>
                </b-row>
                </validation-observer>
            </b-form>

            <b-row>
                <b-col lg="12">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="$router.go(-1)" class="mr-1">
                        <feather-icon icon="ListIcon" class="mr-50"/>
                        <span class="align-middle">Revenir à la liste</span>
                    </b-button>

                    <b-button variant="gradient-primary" type="submit" @click.prevent="downloadFile" v-if="tableauBord.valid">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Exporter</span>
                    </b-button>

                    <b-button variant="gradient-success" type="submit" @click.prevent="validationForm(false)" class="mr-1" v-if="!tableauBord.valid">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">{{creating ? "Ajouter" : "Enregistrer"}}</span>
                    </b-button>

                    <b-button variant="gradient-success" class="mr-1" type="submit" @click.prevent="validationForm(true)" v-if="!creating && !tableauBord.valid">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Valider</span>
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'
    import LoadingCard from "@/views/GDLoadingCard"


    import { formatNumber, itemMatch } from "@/services/utils"
    import useAppConfig from '@core/app-config/useAppConfig'
    import {callUrl, downloadFile} from "@/api/api"
    import { computed, ref } from '@vue/composition-api'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import moment from "moment"
    import tableauBordApi from "@/api/tableauBordApi"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'


    const blocs = {
        FRAIS_GENERAUX: {title: "Frais Généraux", rows: 16},
        FOURN_MOE: {title: "Reste à faire / Fourniture / MOE", rows: 5},
        RELIQUAT: {title: "Reliquat taux horaire et primes", rows: 1},
        TABLEAU_BORD: {title: "Tableaux de bord", rows: 15}
    }

    const RES_EXPLOIT = "RES_EXPLOIT";
    const RES_RETRAITE = "RES_RETRAITE";
    const FRAIS_GENERAUX = "FRAIS_GENERAUX";
    const MBA = "MBA";
    const FOURN_MOE = "FOURN_MOE";

    function onPrevuAnneeChanged(value, ligne) {
                ligne.prevuAnnee = value;
                try {
                    ligne.prevuDate = (this.percentagePrevu * value) / 100;
                } catch(e) {
                    ligne.prevuDate = 0;
                }
            };

    import _ from "lodash"

    export default {
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            VueGoodTable,
            ToastificationContent
        },

        watch: {
            // TODO mutualiser
            fraisGenerauxTotal(value) {

                let ligne = this.lignes.find(ligne => ligne.type.code == FRAIS_GENERAUX)
                this.onPrevuAnneeChanged(value, ligne);
                ligne.prevuAnneePercent = this.getPercentFromCA(ligne);
            },

            resultatExploitation(value) {
                let ligne = this.lignes.find(ligne => ligne.type.code == RES_EXPLOIT)
                this.onPrevuAnneeChanged(value, ligne);
                ligne.prevuAnneePercent = this.getPercentFromCA(ligne);
            },

            resultatRetraite(value) {
                let ligne = this.lignes.find(ligne => ligne.type.code == RES_RETRAITE)
                this.onPrevuAnneeChanged(value, ligne);
                ligne.prevuAnneePercent = this.getPercentFromCA(ligne);
            },

            realiseRetraite(value) {
                let ligne = this.lignes.find(ligne => ligne.type.code == RES_RETRAITE)
                this.onRealiseDateChanged(value, ligne)
            },

            prevuAnneeCA() {

                let ligneFRAIS_GENERAUX = this.lignes.find(ligne => ligne.type.code == FRAIS_GENERAUX)
                let ligneEXPOIT = this.lignes.find(ligne => ligne.type.code == RES_EXPLOIT)
                let ligneRETRAITE = this.lignes.find(ligne => ligne.type.code == RES_RETRAITE)
                let ligneMBA = this.lignes.find(ligne => ligne.type.code == MBA)

                ligneFRAIS_GENERAUX.prevuAnneePercent = this.getPercentFromCA(ligneFRAIS_GENERAUX);
                ligneEXPOIT.prevuAnneePercent = this.getPercentFromCA(ligneEXPOIT);
                ligneRETRAITE.prevuAnneePercent = this.getPercentFromCA(ligneRETRAITE);
                ligneMBA.prevuAnneePercent = this.getPercentFromCA(ligneMBA);
            },

            "ligneMBA.prevuAnnee": function() {

                this.ligneMBA.prevuAnneePercent = this.getPercentFromCA(this.ligneMBA)
            },

            "ligneMBC.prevuAnnee": function() {

                this.ligneMBC.prevuAnneePercent = this.getPercentFromCommande(this.ligneMBC)
            },

            prevuAnneeCommande() {
                
                this.ligneMBC.prevuAnneePercent = this.getPercentFromCommande(this.ligneMBC)
            },
        },

        data() {
            return {
                saving: false,
                fetchingTableau: false,
                fetchingRows: false,
                tableauBord: {date: null, valid: false},
                blocs,
                lignes: [],
                coefReliquat: 0.0,
            }
        },

        beforeMount() {
            if(!this.creating)
                this.refreshTableauBord();
        },

        setup(props, {root}) {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            let creatingBase = !root.$route.params.hasOwnProperty("tableauBordId");
            const creating = ref(creatingBase)

            return { skin, isDark, formatNumber, creating, required}
        },

        computed: {
            dateExport() {return moment(this.tableauBord.date, 'YYYY-MM-DD').format("DD_MM_YYYY");},
            monthNumber() {return parseInt(moment(this.tableauBord.date).format("MM"))},
            percentagePrevu() {return (100 * this.monthNumber) / 12},

            fraisGenerauxTotal() {
                let lignes = this.lignes.filter(ligne => ligne.type.blocCode == FRAIS_GENERAUX);
                return lignes.reduce((tot, ligne) => tot + parseFloat(ligne.prevuAnnee), 0)
            },

            fraisGenerauxTotalReal() {
                let lignes = this.lignes.filter(ligne => ligne.type.blocCode == FRAIS_GENERAUX);
                return lignes.reduce((tot, ligne) => tot + parseFloat(ligne.realiseDate), 0)
            },

            resteAFaireTotal() {
                let lignes = this.lignes.filter(ligne => ligne.type.blocCode == FOURN_MOE);
                return lignes.reduce((tot, ligne) => tot + parseFloat(ligne.prevuAnnee), 0)
            },

            resteAFaireTotalReal() {
                let lignes = this.lignes.filter(ligne => ligne.type.blocCode == FOURN_MOE);
                return lignes.reduce((tot, ligne) => tot + parseFloat(ligne.realiseDate), 0)
            },

            resultatExploitation() {
                if(this.lignes.length == 0)
                    return 0;

                let ligneMBA = this.lignes.find(ligne => ligne.type.code == MBA)
                let ligneFRAIS_GENERAUX =  this.lignes.find(ligne => ligne.type.code == FRAIS_GENERAUX)
                return parseFloat(ligneMBA.prevuAnnee) - parseFloat(ligneFRAIS_GENERAUX.prevuAnnee);
            },

            // prévu a date
            resultatRetraite() {
                if(this.lignes.length == 0)
                    return 0;

                let ligneCOUT = this.lignes.find(ligne => ligne.type.code == "COUT_EXCEP")
                let ligneDETTE = this.lignes.find(ligne => ligne.type.code == "DETTE")
                let ligneCREDIT = this.lignes.find(ligne => ligne.type.code == "CREDITS")

                return this.resultatExploitation - parseFloat(ligneCOUT.prevuAnnee) + parseFloat(ligneCREDIT.prevuAnnee) - parseFloat(ligneDETTE.prevuAnnee);
            },

            realiseRetraite() {
                if(this.lignes.length == 0)
                    return 0;

                let ligneCOUT = this.lignes.find(ligne => ligne.type.code == "COUT_EXCEP")
                let ligneDETTE = this.lignes.find(ligne => ligne.type.code == "DETTE")
                let ligneCREDIT = this.lignes.find(ligne => ligne.type.code == "CREDITS")
                let ligneRES_EXPLOIT = this.lignes.find(ligne => ligne.type.code == "RES_EXPLOIT")

                return parseFloat(ligneRES_EXPLOIT.realiseDate) - parseFloat(ligneCOUT.realiseDate) + parseFloat(ligneCREDIT.realiseDate) - parseFloat(ligneDETTE.realiseDate);
            },

            prevuAnneeCA() {
                if(this.lignes == 0)
                    return 0;
                return this.lignes.find(ligne => ligne.type.code == "CA").prevuAnnee
            },

            ligneMBA() {
                return this.lignes.find(ligne => ligne.type.code == MBA)
            },

            ligneMBC() {
                return this.lignes.find(ligne => ligne.type.code == "MBC")
            },

            prevuAnneeCommande() {
                if(this.lignes == 0)
                    return 0;
                return this.lignes.find(ligne => ligne.type.code == "COMMANDE").prevuAnnee
            },
        },

        methods: {
            async refreshTableauBord() {
                this.fetchingTableau = true;
                this.fetchingRows = true;
                this.tableauBord = await tableauBordApi.getTableauBord(this.$route.params.tableauBordId);
                this.fetchingTableau = false;
                this.lignes = await tableauBordApi.getTableauBordLignes(this.tableauBord.id);
                this.coefReliquat = this.lignes.filter(l => l.type.code === 'MOENT')[0].type.infos;
                await new Promise(resolve => setTimeout(resolve, 500));
                this.fetchingRows = false;
            },

            getLignesForBloc(blocCode) {
                return this.lignes.filter(({type}) => type.blocCode == blocCode)
            },


            async downloadFile() {
                let res = await downloadFile(`api/tableau/bord/export/${this.tableauBord.id}`, `tableau_bord_${this.dateExport}.xlsx`)
                if(res == -1)
                    this.showToast("danger", "Une erreur s'est produite lors de la génération de l'export", "AlertCircleIcon")
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            async validationForm(validate = false) {
                this.$nextTick(async () => {
                    let res = await this.$refs.simpleRules.validate()
                                    
                    if(res) {
                        if(validate) {
                            let {isConfirmed} = await this.$swal({
                                title: 'Valider Tableau de Bord',
                                text: `Etes-vous sûr de vouloir valider le tableau de bord ? Les valeurs des lignes seront enregistrées et il ne sera plus possible de les modifier.`,
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Valider',
                                cancelButtonText: "Annuler",
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                    cancelButton: 'btn btn-outline-danger ml-1',
                                },
                                buttonsStyling: false,
                            })
                            
                            if(!isConfirmed)
                                return;
                        }
                        this.save(validate);
                    }

                    else {
                        this.showToast("danger", "Enregistrement impossible, veuillez vérifier les champs du formulaire", "AlertCircleIcon")
                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".is-invalid:first-of-type");
                            if(el.id == "titre")
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            else  {
                                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }
                            return;
                        });
                    }
                })
            },

            async save(validate) {
                this.tableauBord.valid = validate;
                this.saving = true;

                if(this.creating) {
                    let tableauBordId = await tableauBordApi.addTableauBord(this.tableauBord);
                    this.$router.replace(`/tableau_bord/${tableauBordId}/edit`)
                    this.creating = false;
                    this.refreshTableauBord();
                }
                else {
                    this.tableauBord.valid = validate;
                    await tableauBordApi.saveLines(this.tableauBord.id, this.lignes, validate);
                }

                this.saving = false;
            },

            // les prevu sont utilisées dans des computed, et des watchers
            // leur modification peut entraîner des petits ralentissements si on recalcule
            // trop souvent, d'où l'utilisation d'un debounce (pas de .lazy possible car pas vmodel)
            onPrevuAnneeChanged: onPrevuAnneeChanged,
            onPrevuAnneeChangedDebounced: _.debounce(onPrevuAnneeChanged,500),

            onRealiseDateChanged(value, ligne) {
                ligne.realiseDate = value
            },

            getPercentFromCA(ligne) {
                try {
                    return (ligne.prevuAnnee * 100) / this.prevuAnneeCA
                } catch(e) {
                    return 0
                }
            },

            getPercentFromCommande(ligne) {
                try {
                    return (ligne.prevuAnnee * 100) / this.prevuAnneeCommande
                } catch(e) {
                    return 0
                }
            },

            async onCoefChanged(value) {
                //appel api pour sauvegarder le coef
                await tableauBordApi.updateCoef(this.coefReliquat);
                this.refreshTableauBord();
            }
        }
    }
</script>

<style>
    .skeleton-table thead {
        display: none;
    }

    .skeleton-table td:first-child {
        width: 33% !important;
    }

    .skeleton-table td:not(:first-child) {
        width: 10% !important;
    }

    .tableau-bord td, .tableau-bord th{
        padding-left: 10px;
    }

    .title-row {
        border-bottom: 2px solid transparent;
        border-bottom-color: var(--primary);
    }

    .title-row + tr fieldset {
        padding-top: 5px;
    }

    .tableau-bord .form-control:disabled {
        background-color: #cbcbcb;
    }
</style>